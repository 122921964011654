$primary: #5185c7;
$priceColor: #750914;
$headerColor: #173470;


$global-font-family: "Raleway", sans-serif;
$secondary-font-family: "Roboto", sans-serif;
$global-font-size: 16px;
$global-line-height: 1.19;

$global-font-color: #222222;
$global-link-color: #5284c7;
$hover-color: #2f67b3;
$active-color: #194887;

$global-background-color: #fff;
$secondary-background-color: #efefef;
